export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },
  breadcrumbHome: {
    id: 'hc_navigation_home',
    defaultMessage: 'Home',
  },
  breadcrumbHomeMobile: {
    id: 'hc_navigation_go_back_to_shop',
    defaultMessage: 'Go back to the shop',
  },
  breadcrumbHelpCenter: {
    id: 'hc_navigation_help_center',
    defaultMessage: 'Help center',
  },
}
