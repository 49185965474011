<template>
  <RevContainer>
    <RevBreadcrumb
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="desktopRoutes"
      class="max-md:hidden"
    />
    <RevBreadcrumb
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="mobileRoutes"
      class="pt-12 md:hidden"
    />
  </RevContainer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import type { Bread } from '@ds/components/Breadcrumb/Breadcrumb.vue'
import { RevContainer } from '@ds/components/Container'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import translations from '~/scopes/help-center/components/Breadcrumb/HelpCenterBreadcrumb.translations'
import { HELP_CENTER } from '~/scopes/help-center/routes'
import { HOME } from '~/scopes/home/route-names'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const desktopRoutes: Array<Bread> = [
  {
    title: i18n(translations.breadcrumbHome),
    link: resolveLocalizedRoute({ name: HOME }),
  },
  {
    title: i18n(translations.breadcrumbHelpCenter),
    link: resolveLocalizedRoute({ name: HELP_CENTER.HOME }),
  },
]

const mobileRoutes: Array<Bread> = [
  {
    title: i18n(translations.breadcrumbHomeMobile),
    link: resolveLocalizedRoute({ name: HOME }),
  },
  {
    title: i18n(translations.breadcrumbHelpCenter),
    link: resolveLocalizedRoute({ name: HELP_CENTER.HOME }),
  },
]
</script>
