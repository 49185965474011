<template>
  <div>
    <h2 class="heading-2">{{ i18n(translations.orderLastTitle) }}</h2>

    <OrderlineCard
      class="mt-16"
      :data-qa="`orders-orderitem-${orderline.orderlineId}`"
      :orderline="props.orderline"
    />
  </div>
</template>

<script setup lang="ts">
import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import OrderlineCard from '@/scopes/care-commons/components/order/OrderlineCard/OrderlineCard.vue'

import translations from './OrderLast.translations'

const i18n = useI18n()

type Props = {
  orderline: Orderline
}
const props = defineProps<Props>()
</script>
