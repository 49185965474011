export default {
  title: {
    id: 'hc_title_we_gotchu',
    defaultMessage: '{needHelp, html} We gotchu.',
  },
  needHelp: {
    id: 'hc_title_need_help',
    defaultMessage: 'Need help?',
  },
}
