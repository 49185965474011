export default {
  orderLastTitle: {
    id: 'hc_latest_order_your_latest_order',
    defaultMessage: 'Your latest order',
  },
  orderLastConversation: {
    id: 'hc_open_claims_go_to_conversation',
    defaultMessage: 'Go to conversation',
  },
}
