<template>
  <span class="block h-[80px] w-full md:h-[130px]">
    <span
      class="block h-full w-full"
      :class="[
        props.flipped
          ? '[clip-path:ellipse(50%_100%_at_50%_100%)]'
          : '[clip-path:ellipse(50%_100%_at_50%_0%)]',
        ellipseColor,
      ]"
    />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'

const props = withDefaults(
  defineProps<{
    flipped?: boolean
    ellipseColor: 'purple' | 'grey' | 'dark-grey'
  }>(),
  { flipped: false },
)

const ellipseColor = computed(() => {
  if (props.ellipseColor === 'purple') {
    return tw`bg-surface-brand-hi`
  }

  if (props.ellipseColor === 'dark-grey') {
    return tw`bg-static-default-mid`
  }

  return tw`bg-surface-default-mid`
})
</script>
