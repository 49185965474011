export default {
  orderlinesTitle: {
    id: 'hc_previous_orders_previous_orders',
    defaultMessage: 'Previous orders',
  },
  orderlinesSeeMore: {
    id: 'hc_previous_orders_see_all_orders',
    defaultMessage: 'See all orders',
  },
  orderlineIdLabel: {
    id: 'dashboard_order_item_title_ff_tetris',
    defaultMessage: 'Order # {orderId}',
  },
}
