<template>
  <div>
    <h2 class="font-weight-heading-2 heading-2 relative">
      {{ i18n(translations.title) }}
    </h2>

    <RevAccordionList class="mt-18" hasExternalBorders>
      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.when_should_i_contact_title) }}
        </template>
        <template #body>
          <FormattedMessage
            class="body-1"
            :definition="translations.when_should_i_contact_body"
          >
            <template #login>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.LOGIN"
              >
                {{ i18n(translations.login) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </template>
      </RevAccordionItem>

      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.how_do_i_cancel_an_order_title) }}
        </template>
        <template #body>
          <FormattedMessage
            class="body-1"
            :definition="translations.how_do_i_cancel_an_order_body"
          >
            <template #login>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.LOGIN"
              >
                {{ i18n(translations.login) }}
              </RevLink>
            </template>

            <template #return>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.RETURN_REFUND"
              >
                {{ i18n(translations.returns) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </template>
      </RevAccordionItem>

      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.how_can_i_ask_for_a_repair_title) }}
        </template>
        <template #body>
          <FormattedMessage
            class="body-1"
            :definition="translations.how_can_i_ask_for_a_repair_body"
          >
            <template #login>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.LOGIN"
              >
                {{ i18n(translations.login) }}
              </RevLink>
            </template>

            <template #exceptions>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.WARRANTY"
              >
                {{ i18n(translations.exceptions) }}
              </RevLink>
            </template>

            <template #return>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.RETURN_REFUND"
              >
                {{ i18n(translations.returns) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </template>
      </RevAccordionItem>

      <RevAccordionItem>
        <template #title>
          {{ i18n(translations.how_can_i_get_a_refund_title) }}
        </template>
        <template #body>
          <FormattedMessage
            class="body-1"
            :definition="translations.how_can_i_get_a_refund_body"
          >
            <template #login>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.LOGIN"
              >
                {{ i18n(translations.login) }}
              </RevLink>
            </template>

            <template #refund>
              <RevLink
                class="body-1-bold"
                target="_blank"
                :to="FAQ_LINKS.RETURN_REFUND"
              >
                {{ i18n(translations.refund) }}
              </RevLink>
            </template>
          </FormattedMessage>
        </template>
      </RevAccordionItem>
    </RevAccordionList>
  </div>
</template>

<script setup lang="ts">
import { useHead, useRouteLocationWithLocale } from '#imports'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'
import { DASHBOARD } from '~/scopes/dashboard/routes'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'
import { buildFaqSchema, prepareJsonLdScript } from '~/utils/seo/schema'

import translations from './SeoBlock.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const FaqSeoData = [
  {
    question: i18n(translations.when_should_i_contact_title),
    answer: i18n(translations.when_should_i_contact_jsonld, {
      login: i18n(translations.login),
    }),
  },
  {
    question: i18n(translations.how_do_i_cancel_an_order_title),
    answer: i18n(translations.how_do_i_cancel_an_order_jsonld, {
      login: i18n(translations.login),
      return: i18n(translations.returns),
    }),
  },
  {
    question: i18n(translations.how_can_i_ask_for_a_repair_title),
    answer: i18n(translations.how_can_i_ask_for_a_repair_jsonld, {
      login: i18n(translations.login),
      exceptions: i18n(translations.exceptions),
      return: i18n(translations.returns),
    }),
  },
  {
    question: i18n(translations.how_can_i_get_a_refund_title),
    answer: i18n(translations.how_can_i_get_a_refund_jsonld, {
      login: i18n(translations.login),
      refund: i18n(translations.refund),
    }),
  },
]

const FAQ_LINKS = {
  LOGIN: resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF }),
  RETURN_REFUND: zendeskFaqBaseUrl.concat(
    '/articles/360014698640-Returns-and-refunds-instructions',
  ),
  WARRANTY: resolveLocalizedRoute({
    name: CMS.LEGAL_PAGE,
    params: {
      pageName: LEGAL_PAGE_NAME.WARRANTY,
    },
  }),
}

useHead(() => ({
  script: [prepareJsonLdScript(buildFaqSchema(FaqSeoData))],
}))
</script>
