<template>
  <div class="space-y-16" data-qa="other-orderlines">
    <h2 class="heading-2">{{ i18n(translations.orderlinesTitle) }}</h2>

    <div class="mt-16 grid gap-16">
      <RevLink
        v-for="orderline in props.orderlines"
        :key="orderline.orderlineId"
        class="hover:no-underline"
        :data-qa="`orderline-${orderline.orderlineId.toString()}`"
        full-width
        :to="getOrderlineUrl(orderline)"
        :underlined="false"
      >
        <RevCard class="flex items-center gap-8 px-24 py-18" hoverable>
          <picture class="shrink-0">
            <RevIllustrationSource
              :height="40"
              :media="BreakpointQueries[Breakpoint.LG]"
              :src="orderline.product.image"
              :width="40"
            />

            <RevIllustration
              :alt="orderline.product.title"
              :height="40"
              :src="orderline.product.image"
              :width="40"
            />
          </picture>

          <div class="grow text-left">
            <p class="text-static-default-hi body-1">
              {{ orderline.product.title }}
            </p>

            <p class="text-static-default-low body-2">
              {{
                i18n(translations.orderlineIdLabel, {
                  orderId: orderline.orderlineId,
                })
              }}
            </p>
          </div>

          <div class="shrink-0">
            <IconChevronRight />
          </div>
        </RevCard>
      </RevLink>
    </div>

    <RevLink
      class="body-1 block text-right"
      :to="
        resolveLocalizedRoute({
          type: 'internal',
          name: DASHBOARD.MY_ORDERS.SELF,
        })
      "
    >
      {{ i18n(translations.orderlinesSeeMore) }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  Breakpoint,
  BreakpointQueries,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { RevLink } from '@ds/components/Link'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './Orderlines.translations'

type Props = {
  orderlines: Orderline[]
}
const props = defineProps<Props>()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const i18n = useI18n()

function getOrderlineUrl(orderline: Orderline) {
  return resolveLocalizedRoute({
    type: 'internal',
    name: DASHBOARD.MY_ORDERS.SELF,
    hash: {
      orderline: `${orderline.orderId}-${orderline.orderlineId}`,
    },
  })
}
</script>
