export default {
  title: {
    id: 'hc_search_search_faq_articles',
    defaultMessage: 'Search our FAQ articles',
  },
  howCanWeHelp: {
    id: 'hc_search_try_something_like',
    defaultMessage: 'How can we help?',
  },
  clearButtonAriaLabel: {
    id: 'asp_action_bar_select_template_clear',
    defaultMessage: 'Clear',
  },
  seeMoreResult: {
    id: 'hc_search_see_more_results',
    defaultMessage: 'See more results',
  },
}
