export default {
  footer_need_help: {
    id: 'hc_footer_need_help_with_order',
    defaultMessage:
      'If you need help with a Back Market order, please first {reach_out_seller, html}',
  },
  footer_need_help_cta: {
    id: 'hc_footer_contact_the_seller',
    defaultMessage: 'message us here',
  },
  footer_questions: {
    id: 'hc_footer_need_help_contact_bm',
    defaultMessage: 'Still got questions? {contact, html}',
  },
  footer_questions_cta: {
    id: 'hc_footer_contact_back_market',
    defaultMessage: 'contact us here',
  },
} as const
