<template>
  <div>
    <FormattedMessage
      class="body-1"
      :definition="translations.footer_need_help"
      tag="p"
    >
      <template #reach_out_seller>
        <RevLink
          target="_blank"
          :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
        >
          {{ i18n(translations.footer_need_help_cta) }}
        </RevLink>
      </template>
    </FormattedMessage>

    <FormattedMessage
      class="body-1"
      :definition="translations.footer_questions"
      tag="p"
    >
      <template #contact>
        <RevLink
          data-qa="contact-link"
          :to="zendeskFaqBaseUrl + '/requests/new'"
        >
          {{ i18n(translations.footer_questions_cta) }}
        </RevLink>
      </template>
    </FormattedMessage>
  </div>
</template>

<script setup lang="ts">
import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './FooterBlock.translations'

const i18n = useI18n()

const resolveLocalizedRoute = useRouteLocationWithLocale()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()
</script>
