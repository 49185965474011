<template>
  <div class="flex flex-col gap-16">
    <div class="flex flex-col gap-8">
      <h2 class="heading-2">{{ i18n(translations.loginTitle) }}</h2>
      <p class="body-1">{{ i18n(translations.loginDescription) }}</p>
    </div>

    <RevButton
      :to="{ name: ROUTES.AUTH.LOGIN, query: { next: $route.path } }"
      variant="primary"
    >
      {{ i18n(translations.loginButton) }}
    </RevButton>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import { ROUTES } from '~/scopes/auth/route-names'

import translations from './Login.translations'

const i18n = useI18n()
</script>
