export default {
  loginTitle: {
    id: 'hc_login_log_in_fastest_solution',
    defaultMessage: 'Log in for the fastest solution',
  },
  loginDescription: {
    id: 'hc_login_real_live_humans',
    defaultMessage:
      'This gives real live humans the info they need to help you with your order or issue stat.',
  },
  loginButton: {
    id: 'hc_login_log_in',
    defaultMessage: 'Log in',
  },
}
