export default {
  reassurance_reply: {
    id: 'hc_footer_get_a_reply',
    defaultMessage: 'Get a reply within 24 hours from Monday to Saturday',
  },
  reassurance_enjoy_friendly_help: {
    id: 'hc_footer_enjoy_friendly_help',
    defaultMessage: 'Enjoy friendly help from actual humans',
  },
} as const
