<template>
  <div
    class="bg-static-default-hi rounded-sm flex flex-col gap-32 px-24 py-24 md:flex-row md:items-center md:py-16"
  >
    <div class="flex basis-1/2 items-center gap-12">
      <IconClock class="h-48 w-48" />
      <div class="body-2">{{ i18n(translations.reassurance_reply) }}</div>
    </div>
    <div class="flex basis-1/2 items-center gap-12">
      <IconHeadset class="h-48 w-48" />
      <div class="body-2">
        {{ i18n(translations.reassurance_enjoy_friendly_help) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconClock } from '@ds/icons/IconClock'
import { IconHeadset } from '@ds/icons/IconHeadset'

import translations from './Reassurance.translations'

const i18n = useI18n()
</script>
