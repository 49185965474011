export default {
  title: {
    id: 'hc_seo_most_frequently_asked_questions',
    defaultMessage: 'Most frequently asked questions',
  },
  login: {
    id: 'hc_seo_logging_in_to_your_bm_account',
    defaultMessage: 'go to your Back Market account',
  },
  refund: {
    id: 'hc_seo_learn_more_about_requesting_a_refund',
    defaultMessage: 'Learn more about requesting a refund',
  },
  returns: {
    id: 'hc_seo_learn_more_about_returns',
    defaultMessage: 'Learn more about returns',
  },
  exceptions: {
    id: 'hc_seo_repair_exceptions',
    defaultMessage: 'exceptions',
  },
  when_should_i_contact_title: {
    id: 'hc_seo_when_do_i_contact_the_seller',
    defaultMessage: 'When do I contact Back Market?',
  },
  when_should_i_contact_body: {
    id: 'hc_seo_when_do_i_contact_the_seller_body',
    defaultMessage:
      'Back Market is a marketplace where expert refurbishers sell their inventory. That means items you buy on Back Market come to you straight from them, not from us. Still, Back Market should be your main point of contact if any issues come up. To start a conversation with us, {login, html} and select “Ask for help” next to the relevant order. This takes you to our online messaging platform where we’re happy to chat with you!',
  },
  when_should_i_contact_jsonld: {
    id: 'hc_seo_when_do_i_contact_the_seller_body_jsonld_schema',
    defaultMessage:
      'Back Market is a marketplace where expert refurbishers sell their inventory. That means items you buy on Back Market come to you straight from them, not from us. Still, Back Market should be your main point of contact if any issues come up. To start a conversation with us, {login, html} and select “Ask for help” next to the relevant order. This takes you to our online messaging platform where we’re happy to chat with you!',
  },

  how_do_i_cancel_an_order_title: {
    id: 'hc_seo_how_do_i_cancel_an_order',
    defaultMessage: 'How do I cancel an order?',
  },
  how_do_i_cancel_an_order_body: {
    id: 'hc_seo_how_do_i_cancel_an_order_body',
    defaultMessage:
      'You have 30 days to change your mind about an item from the time you receive it. If your order hasn’t been confirmed yet — indicated by the “Ordered” status — {login, html} > “Ask for Help” > “Cancel my order”. If your order is confirmed and has a “Preparing your order” or “Shipped” status, you’ll need to wait until you receive the package. Then, log in to your account > “Orders” > “Ask for help” > select the reason for your return. {return, html}',
  },
  how_do_i_cancel_an_order_jsonld: {
    id: 'hc_seo_how_do_i_cancel_an_order_body_jsonld_schema',
    defaultMessage:
      'You have 30 days to change your mind about an item from the time you receive it. If your order hasn’t been confirmed yet — indicated by the “Ordered” status — {login, html} > “Ask for Help” > “Cancel my order”. If your order is confirmed and has a “Preparing your order” or “Shipped” status, you’ll need to wait until you receive the package. Then, log in to your account > “Orders” > “Ask for help” > select the reason for your return. {return, html}',
  },

  how_can_i_get_a_refund_title: {
    id: 'hc_seo_how_can_i_get_a_refund',
    defaultMessage: 'How can I get a refund?',
  },
  how_can_i_get_a_refund_body: {
    id: 'hc_seo_how_can_i_get_a_refund_body',
    defaultMessage:
      'You can get a refund by returning your undamaged item within 30 days of delivery. To start a return, {login, html}, select “Ask for help”, and follow the steps. You’ll be able to choose a repair, replacement, or refund. {refund, html}',
  },
  how_can_i_get_a_refund_jsonld: {
    id: 'hc_seo_how_can_i_get_a_refund_body_jsonld_schema',
    defaultMessage:
      'You can get a refund by returning your undamaged item within 30 days of delivery. To start a return, {login}, select “Ask for help”, and follow the steps. You’ll be able to choose a repair, replacement, or refund. {refund}',
  },

  how_can_i_ask_for_a_repair_title: {
    id: 'hc_seo_how_can_i_ask_for_a_repair',
    defaultMessage: 'How can I ask for a repair?',
  },
  how_can_i_ask_for_a_repair_body: {
    id: 'hc_seo_how_can_i_ask_for_a_repair_body',
    defaultMessage:
      'Most repairs are covered by the warranty you automatically receive with each item purchased. Note the length of the warranty depends on your country, but they start at a minimum of 1 year. Also, there are a few rare {exceptions, html} that render the warranty invalid. To ask for a repair, {login, html} and select “Ask for help” next to the relevant order and follow the steps. {return, html}',
  },
  how_can_i_ask_for_a_repair_jsonld: {
    id: 'hc_seo_how_can_i_ask_for_a_repair_body_jsonld_schema',
    defaultMessage:
      'Most repairs are covered by the warranty you automatically receive with each item purchased. Note the length of the warranty depends on your country, but they start at a minimum of 1 year. Also, there are a few rare {exceptions} that render the warranty invalid. To ask for a repair,  {login} and select “Ask for help” next to the relevant order and follow the steps. {return}',
  },
}
