<template>
  <FormattedMessage
    class="text-static-default-hi font-punchline heading-1 text-left md:punchline md:text-center"
    :definition="translations.title"
    tag="div"
  >
    <template #needHelp>
      {{ i18n(translations.needHelp) }}
    </template>
  </FormattedMessage>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './Title.translations'

const i18n = useI18n()
</script>
