<template>
  <NuxtLayout name="default">
    <template #header>
      <div class="bg-surface-brand-hi pb-8 md:pb-24">
        <Breadcrumb />
        <div
          class="mx-auto max-w-full px-24 max-md:mt-24 md:max-w-[1184px] md:px-32"
        >
          <div class="h-12 md:h-16 lg:h-20">
            <TheLogo />
          </div>
          <div class="text-static-default-hi heading-2 mt-8 max-md:hidden">
            {{ i18n(translations.helpCenterLayoutTitle) }}
          </div>
        </div>
      </div>
    </template>
    <slot />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import Breadcrumb from '~/scopes/help-center/components/Breadcrumb/Breadcrumb.vue'
import TheLogo from '~/scopes/navigation/components/TheLogo.vue'

import translations from './HelpCenterLayout.translations'

const i18n = useI18n()
</script>
